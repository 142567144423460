<script setup lang="ts">
import useVuelidate from "@vuelidate/core";

const { t } = useI18n();

interface stateStepRules {
  productnumber: {};
  serial: {};
  serial2: {};
  manufacturingmonth: {};
  manufacturingyear: {};
  invoicedate: {};
}

const props = defineProps<{
  state: {
    productnumber: string;
    productname: string;
    serial: string;
    serial2: string;
    manufacturingmonth: string;
    manufacturingyear: string;
    invoicedate: string;
    accessories: string;
  };
  rules: stateStepRules;
  isInvoiceInFuture: boolean;
}>();

const { rules, state } = toRefs(props);

// Rules
const stateRules = reactive({
  ...rules.value,
});

const manufacturerYearComputed = computed(() => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: currentYear - 2000 + 1 }, (_, index) =>
    (2000 + index).toString(),
  );
});

const v2 = useVuelidate(stateRules, state);

const isProductNumberValid = ref(false);
const isLoading = ref(false);
const productObject = ref();

async function validateProductNumber() {
  isProductNumberValid.value = false;
  if (state.value.productnumber?.length > 0) {
    try {
      isLoading.value = true;

      let object = await useFetch(
        `/api/contact/product/${state.value.productnumber}`,
      );

      if (object.data.value) {
        productObject.value = object.data.value;
        isProductNumberValid.value = true;
      } else {
        state.value.productname = "";
      }
    } catch (error) {
      console.error("Error during API call:", error);
      isProductNumberValid.value = false;
      state.value.productname = "";
    }

    isLoading.value = false;
  }
}

watch(productObject, (newProduct) => {
  if (newProduct) {
    state.value.productnumber = newProduct.productNumber;
    state.value.productname = newProduct.productDescription;
  } else {
    state.value.productnumber = "";
    state.value.productname = "";
  }
});

defineExpose({
  isProductNumberValid,
});

onMounted(() => {
  v2.value.$reset();
  validateProductNumber();
});
</script>

<template>
  <div class="relative">
    <ScheppachTextInput
      v-model="state.productnumber"
      :required="true"
      :label="t('form.contactForm.articleNumber')"
      :placeholder="t('form.contactForm.articleNumberPlaceholder')"
      :is-valid="isProductNumberValid"
      :input-error="
        v2.productnumber?.$error ||
        (!isLoading && !isProductNumberValid && state.productnumber?.length > 0)
      "
      :input-error-message="t('form.contactForm.noValidArticleNumber')"
      @blur="validateProductNumber(), v2.productnumber?.$touch()"
    />

    <div class="absolute top-9 right-3">
      <ScheppachSpinnerSmall v-if="isLoading" />
      <i
        v-if="!isLoading && isProductNumberValid && state.productnumber != ''"
        class="i-sl-check-1 c-scheppach-success-500 inline-block"
      ></i>
    </div>
  </div>

  <ScheppachTextInput
    v-model="state.productname"
    :label="t('form.contactForm.machineDesignation')"
    :placeholder="t('form.contactForm.machineDesignationPlaceholder')"
  />

  <div class="flex flex-col sm:flex-row gap-3">
    <ScheppachTextInput
      v-model="state.serial"
      class="w-full sm:w-1/2"
      :label="t('form.contactForm.serialNumber1')"
      :required="true"
      :placeholder="t('form.contactForm.serialNumberPlaceholder1')"
      :input-error="v2.serial?.$error"
      :input-error-message="v2.serial?.$errors[0]?.$message"
      @blur="v2.serial?.$touch()"
    />

    <ScheppachTextInput
      v-model="state.serial2"
      class="w-full sm:w-1/2"
      :label="t('form.contactForm.serialNumber2')"
      :required="true"
      :placeholder="t('form.contactForm.serialNumberPlaceholder2')"
      :input-error="v2.serial2?.$error"
      :input-error-message="v2.serial2?.$errors[0]?.$message"
      @blur="v2.serial2?.$touch()"
    />
  </div>

  <div class="flex flex-col sm:flex-row gap-3">
    <!-- Manufacturer Month -->
    <div class="w-full sm:w-1/3">
      <label
        for="selectCountry"
        class="text-scheppach-primary-500 text-sm md:text-base mb--3"
      >
        {{ t("form.contactForm.monthOfCreation") }} *
      </label>
      <select
        id="selectCountry"
        v-model="state.manufacturingmonth"
        required
        class="sw-search-input placeholder-text-scheppach-neutral-500 text-scheppach-primary-500 bg-scheppach-neutral-50 p-3 outline-none w-full b-b"
        :class="[
          v2.manufacturingmonth?.$error
            ? 'b-scheppach-error-500'
            : 'b-scheppach-primary-700',
        ]"
        @blur="v2.manufacturingmonth.$touch()"
      >
        <option disabled selected value="">
          {{ t("form.contactForm.monthOfCreationPlaceholder") }}
        </option>
        <option v-for="month in 12" :key="month" :value="month">
          {{ month }}
        </option>
      </select>
      <span
        v-if="v2.manufacturingmonth.$error"
        class="text-sm c-scheppach-error-500 focus:ring-primary border-secondary-300"
      >
        {{ v2.manufacturingmonth.$errors[0]?.$message }}
      </span>
    </div>

    <!-- Manufacturer Year -->
    <div class="w-full sm:w-1/3">
      <label
        for="selectCountry"
        class="text-scheppach-primary-500 text-sm md:text-base mb--3"
      >
        {{ t("form.contactForm.yearOfCreation") }} *
      </label>
      <select
        id="selectCountry"
        v-model="state.manufacturingyear"
        required
        class="sw-search-input placeholder-text-scheppach-neutral-500 text-scheppach-primary-500 bg-scheppach-neutral-50 p-3 outline-none w-full b-b"
        :class="[
          v2.manufacturingyear?.$error
            ? 'b-scheppach-error-500'
            : 'b-scheppach-primary-700',
        ]"
        @blur="v2.manufacturingyear.$touch()"
      >
        <option disabled selected value="">
          {{ t("form.contactForm.yearOfCreationPlaceholder") }}
        </option>
        <option
          v-for="year in manufacturerYearComputed"
          :key="year"
          :value="year"
        >
          {{ year }}
        </option>
      </select>
      <span
        v-if="v2.manufacturingyear.$error"
        class="text-sm c-scheppach-error-500 focus:ring-primary border-secondary-300"
      >
        {{ v2.manufacturingyear.$errors[0]?.$message }}
      </span>
    </div>

    <ScheppachTextInput
      v-model="state.invoicedate"
      class="w-full sm:w-1/3"
      :label="t('form.contactForm.purchaseDate')"
      :required="true"
      :type="'date'"
      :placeholder="t('form.contactForm.purchaseDatePlaceholder')"
      :input-error="v2.invoicedate?.$error || isInvoiceInFuture"
      :input-error-message="
        isInvoiceInFuture
          ? t('form.futureInvoiceDate')
          : v2.invoicedate?.$errors[0]?.$message
      "
      @blur="v2.invoicedate?.$touch()"
    />
  </div>

  <label
    class="text-scheppach-primary-500 text-sm md:text-base"
    for="accessories"
  >
    {{ t("form.contactForm.includedSupply") }}
    <textarea
      id="accessories"
      v-model="state.accessories"
      class="mb-3 h-35 placeholder-text-scheppach-neutral-500 text-scheppach-primary-500 bg-scheppach-neutral-50 p-3 outline-none w-full b-b"
      :placeholder="t('form.contactForm.includedSupplyPlaceholder')"
    >
    </textarea>
  </label>
</template>
